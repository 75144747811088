import { useState } from "react";

function useForm(initialState = {}, validations = [], onSubmit = () => { }) {
  // Add the 'onSubmit' argument
  const { isValid: initialIsValid, errors: initialErrors } = validate(
    validations,
    initialState
  );
  const [inputValues, setValues] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [isValid, setValid] = useState(initialIsValid);
  const [touched, setTouched] = useState({});
  const [secrets, setSecrets] = useState('');
  const [secretsSecond, setSecretsSecond] = useState('');
  const [userSecrets, setUserSecrets] = useState('');
  const [inputChanged, setInputChanged] = useState(false);

  const maskEin = (str) => {
    const x = str.replace(/\D/g, '').match(/(\d{0,2})(\d{0,7})/);
    let ein = !x[2] ? x[1] : x[1] + '-' + x[2];

    if(ein.length < 10) {
      const lastChar = ein.slice(-1);
      ein = ein.replace(/\d/g, '*');
      ein = ein.slice(0, -1) + lastChar;
    } else {
      ein = ein.replace(/\d/g, '*');
    }
    return ein;
  }

  const changeHandler = (event) => {

    if (["phoneNo", "secondPhoneNo"].includes(event.target.name)) {
      var x = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value = x[0].startsWith('0') || x[0].startsWith('1') ? '' : !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    } else if (["zipcode", "mailZipcode", "duplicateZipcode"].includes(event.target.name)) {
      x = event.target.value.replace(/[^0-9]+/g, "").slice(0, 5)
      event.target.value = x;
    } else if (["extension", "secondExtension"].includes(event.target.name)) {
      x = event.target.value.replace(/[^0-9]+/g, "").slice(0, 7)
      event.target.value = x;
    } else if (event.target.name === "crdIndividual" || event.target.name === "crdFirm") {
      x = event.target.value.replace(/[^0-9]+/g, "").slice(0, 7)
      event.target.value = x;
    } else if (["dateOfBirth", "dateOfBirthSecond", "dateOfIncorporation"].includes(event.target.name)) {
      x = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
      event.target.value = !x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? '/' + x[3] : '');
    } else if (["ssn"].includes(event.target.name)) {
      x = event.target.value.length == 1 ? event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/) : inputValues.ssn.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      let ssn = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

      if(ssn.length < 11) {
        const lastChar = ssn.slice(-1);
        ssn = ssn.replace(/\d/g, '*');
        ssn = ssn.slice(0, -1) + lastChar;
      } else {
        ssn = ssn.replace(/\d/g, '*');
      }
      setSecrets(ssn);
    } else if (["ssnSecond"].includes(event.target.name)) {
      x = event.target.value.length == 1 ? event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/) : inputValues.ssnSecond.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      let ssnSecond = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

      if(ssnSecond.length < 11) {
        const lastChar = ssnSecond.slice(-1);
        ssnSecond = ssnSecond.replace(/\d/g, '*');
        ssnSecond = ssnSecond.slice(0, -1) + lastChar;
      } else {
        ssnSecond = ssnSecond.replace(/\d/g, '*');
      }
      setSecretsSecond(ssnSecond);
    } else if (["ein"].includes(event.target.name)) {
      x = event.target.value.length == 1 ? event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,7})/) : inputValues.ein.replace(/\D/g, '').match(/(\d{0,2})(\d{0,7})/);
      let ein = !x[2] ? x[1] : x[1] + '-' + x[2];

      if(ein.length < 10) {
        const lastChar = ein.slice(-1);
        ein = ein.replace(/\d/g, '*');
        ein = ein.slice(0, -1) + lastChar;
      } else {
        ein = ein.replace(/\d/g, '*');
      }
      setSecrets(ein);
    } else if (["userSsn"].includes(event.target.name)) {
      x = event.target.value.length == 1 ? event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/) : inputValues.userSsn.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      let ssn = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

      if(ssn.length < 11) {
        const lastChar = ssn.slice(-1);
        ssn = ssn.replace(/\d/g, '*');
        ssn = ssn.slice(0, -1) + lastChar;
      } else {
        ssn = ssn.replace(/\d/g, '*');
      }
      setUserSecrets(ssn);
    }

    if(!(["ssn"].includes(event.target.name) || ["ssnSecond"].includes(event.target.name) || ["ein"].includes(event.target.name) || ["userSsn"].includes(event.target.name))) {
      const newValues = { ...inputValues, [event.target.name]: event.target.value };
      const { isValid, errors } = validate(validations, newValues);
      setValues(newValues);
      setValid(isValid);
      setErrors(errors);
      setTouched({ ...touched, [event.target.name]: true });
    }

    if (event.target.value !== "") {
      setInputChanged(true);
    }
  };

  const changeKeyHandler = (event) => {
    let secret = '';
    if (["ssn"].includes(event.target.name)) {
      secret = inputValues.ssn;
      let x = secret.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      secret = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

      if (event.key * 1 >= 0 && event.key * 1 <=9 && inputValues.ssn?.length < 11) {
        secret = secret + event.key;
      } else if (event.key.toLowerCase() == 'backspace') {
        secret = secret.slice(0, -1);
      }
    } else if (["ssnSecond"].includes(event.target.name)) {
      secret = inputValues.ssnSecond;
      let x = secret.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      secret = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

      if (event.key * 1 >= 0 && event.key * 1 <=9 && inputValues.ssnSecond?.length < 11) {
        secret = secret + event.key;
      } else if (event.key.toLowerCase() == 'backspace') {
        secret = secret.slice(0, -1);
      }
    } else if (["ein"].includes(event.target.name)) {
      secret = inputValues.ein;
      let x = secret.replace(/\D/g, '').match(/(\d{0,2})(\d{0,7})/);
      secret = !x[2] ? x[1] : x[1] + '-' + x[2];

      if (event.key * 1 >= 0 && event.key * 1 <=9 && inputValues.ein?.length < 10) {
        secret = secret + event.key;
      } else if (event.key.toLowerCase() == 'backspace') {
        secret = secret.slice(0, -1);
      }
    } else if (["userSsn"].includes(event.target.name)) {
      secret = inputValues.userSsn;
      let x = secret.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      secret = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

      if (event.key * 1 >= 0 && event.key * 1 <=9 && inputValues.userSsn?.length < 11) {
        secret = secret + event.key;
      } else if (event.key.toLowerCase() == 'backspace') {
        secret = secret.slice(0, -1);
      }
    }

    const newValues = { ...inputValues, [event.target.name]: secret };
    const { isValid, errors } = validate(validations, newValues);
    setValues(newValues);
    setValid(isValid);
    setErrors(errors);
    setTouched({ ...touched, [event.target.name]: true });
  }

  const submitHandler = () => {
    // event.preventDefault();
    setInputChanged(false);
    onSubmit(inputValues);
  };

  const inputChangedHandler = () => {
    setInputChanged(false);
  }

  const updateInputValues = (key, value, enable = true) => {
    const newValues = { ...inputValues, [key]: value };
    const { isValid, errors } = validate(validations, newValues);
    setValues(newValues);
    setValid(isValid);
    setErrors(errors);

    if (value !== "" && enable) {
      setInputChanged(true);
    }
  }

  function validate(validations, values) {
    const errors = validations
      .map((validation) => validation(values))
      .filter((validation) => typeof validation === "object");
    return {
      isValid: errors.length === 0,
      errors: errors.reduce((errors, error) => ({ ...errors, ...error }), {}),
    };
  }

  const initValues = (values) => {
    const newValues = { ...initialState, ...values };
    const { isValid, errors } = validate(validations, newValues);
    setValues(newValues);
    setValid(isValid);
    setErrors(errors);
    if (values.hasOwnProperty("ein") && values.ein.length > 0) {
      setSecrets(maskEin(values.ein));
    }
    let _touched = {}
    Object.keys(values).map(key => {
      _touched = {
        ..._touched,
        [key]: true
      }
    })
    setTouched(_touched)
  }
  return {
    inputValues,
    changeHandler,
    changeKeyHandler,
    isValid,
    errors,
    touched,
    submitHandler,
    inputChangedHandler,
    initValues,
    setErrors,
    secrets,
    secretsSecond,
    userSecrets,
    inputChanged,
    updateInputValues
  };
}

export { useForm };
