import React, { useEffect, useState } from "react";
import { signup, signupWithInvite } from "../utils/auth";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import { actionTypes as appActionTypes } from '../store/App/type';
import { client as prismicClient } from "../libs/prismic";
import EarlyAccessGateRegister from "../components/EarlyAccessGate/EarlyAccessGateRegister";
import EarlyAccessGateDone from "../components/EarlyAccessGate/EarlyAccessGateDone";
import "../styles/pages/signup.scss";

const Signup = ({ location }) => {
  
  const dispatch = useDispatch();

  const [signupDoc, setSignupDoc] = useState();
  const [earlyAccessGate, setEarlyAccessGate] = useState(false);
  const [step, setStep] = useState('early_access_gate_step1')

  const params = new URLSearchParams(location.search);
  const invitation = params.get("invitation");

  const decodeToObject = (encodedString) => {
      // Decode the Base64 string
      let decodedString = atob(encodedString);
      // Parse the decoded string to an object
      let obj;
      try {
          obj = JSON.parse(decodedString);
      } catch (e) {
          console.error('Parsing error:', e);
          return null;
      }
      // Return the object
      return obj;
  }

  useEffect(() => {
    async function fetchAndSetData() {
      const signupDoc = await prismicClient().getSingle("sign_up");

      let early_access_gate = signupDoc.data.early_access_gate ? true : false;
      // early_access_gate = false;
      setEarlyAccessGate(early_access_gate);
      setSignupDoc(signupDoc);

      if (early_access_gate) {

      } else {
        const object = decodeToObject(invitation);
        console.log('invitation object', object)
        if (object) {
          dispatch({ type: appActionTypes.UPDATE_INVITATION_INFO, value: object });
          signupWithInvite(invitation);
        } else {
          signup();
        }
      }
    }

    fetchAndSetData();
  }, []);
  
  return (
    signupDoc && earlyAccessGate ? (
      <div className="early-access-gate">
        {(step === 'early_access_gate_step1') && 
            <EarlyAccessGateRegister 
              signupDoc={signupDoc}
              handleSignup={() => setStep('early_access_gate_step2')}
            />
        } 
        {(step === 'early_access_gate_step2') && 
            <EarlyAccessGateDone 
              signupDoc={signupDoc}
            />}
      </div>
    ) : (
      <div><Loader /></div>
    )
  );
}

export default Signup;