import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Button, MenuItem } from "@mui/material";
import { Link } from "gatsby";
import { useForm } from "../../../hooks/useForm";
import { isRequired, isText, isValidEmail, isPhoneNumber } from "../../../utils/validationFunc";
import TextInputField from "../../TextInputField";
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";
import useSelectStyles from "../../../styles/components/Select";
import { RichText } from "prismic-reactjs";
import {
  INTEREST_LEVEL_ITEMS,
} from "../../../constants/index";
import SignupService from "../../../services/SignupService";

const signupService = new SignupService();

const EarlyAccessGateRegister = ({ handleSignup, signupDoc }) => {
  const dispatch = useDispatch();
  const classes = useSelectStyles();
  const [showError, setShowError] = useState(false);

  const initialState = {
    firstName: "",
    lastName: "",
    phoneNo: "",
    email: "",
    company: "",
    interestLevel: "",
  };

  const validations = [
    ({ firstName }) =>
      isRequired(firstName) || {
        firstName: "This field is required",
      },
    ({ firstName }) =>
      isText(firstName) || {
        firstName: "Field can only contain letters A - Z, numbers 0 - 9, ampersand (&) and hyphen (-).",
      },
    ({ lastName }) =>
      isRequired(lastName) || { lastName: "This field is required" },
    ({ lastName }) =>
      isText(lastName) || {
        lastName: "Field can only contain letters A - Z, numbers 0 - 9, ampersand (&) and hyphen (-).",
      },
    ({ phoneNo }) =>
      isPhoneNumber(phoneNo) || {
        phoneNo: "Please enter a valid phone number.",
      },
    ({ phoneNo }) =>
      isRequired(phoneNo) || {
        phoneNo: "This field is required",
      },
    ({ email }) =>
      isRequired(email) || {
        email: "This field is required",
      },
    ({ email }) =>
      isValidEmail(email) || {
        email: "This field is email format",
      },
    ({ interestLevel }) =>
      isRequired(interestLevel) || {
        interestLevel: "This field is required",
      },      
  ];

  const onSubmit = async (formValues) => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_save_loading', show: true, showSub: false, subType: null } });

    const result = await signupService.registerEarlyAccessGate(formValues);
    console.log("registerEarlyAccessGate", result);

    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_save_loading', show: false, showSub: false, subType: null } });

    if (result) {
      handleSignup();
    }
  };

  useEffect(() => {
    initValues(initialState);
  }, [])

  const { inputValues, changeHandler, changeKeyHandler, errors, isValid, submitHandler, initValues, secrets, secretsSecond } =
    useForm(initialState, validations, onSubmit);

  return (
    <div className="signup-page">
      <div className="d-flex mx-auto mw-1440">
        <div className="signup-info-container">
          <div className="signup-logo-container d-none d-lg-block mx-auto">
            <Link to="/">
              <img src={signupDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>            
          </div>
          <div className="signup-info text-center text-lg-start">
            <h6>{signupDoc.data?.title[0].text}</h6>
            <RichText render={signupDoc.data?.description} />
          </div>
        </div>
        <div className="signup-form-container">
          <div className="signup-logo-container text-center d-lg-none">
            <Link to="/">
              <img src="https://i.ibb.co/JmkcRBq/opalvest.png" alt="Brand Logo"/>
            </Link>
          </div>
          <div className="signup-form">
              <div className="signup-text text-center text-lg-start">
                  <h2>Early Access & Partnerships</h2>
              </div>
              <div className="signup-text-description text-center text-lg-start">
                  <p>Opalvest will soon offer its inaugural Opals to select investors and wealth managers.</p>
                  <p>For early access or partnership inquiries, please share your information and we’ll be in touch.</p>
              </div>
              <div className="form-container">
                <div className="mb-10">
                  <TextInputField
                    label="First name"
                    variant="filled"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        borderColor:
                          (showError && errors.firstName) && "#C75E5A",
                      },
                    }}
                    onChange={changeHandler}
                    name="firstName"
                    value={inputValues.firstName}
                    style={{ marginTop: 10, width: "100%" }}
                    InputLabelProps={{
                      style: {
                        color:
                          (showError && errors.firstName)
                            ? "#C75E5A"
                            : "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  />
                  {showError && errors.firstName && (
                    <p className="error-message">{errors.firstName}</p>
                  )}                          
                </div>
                <div className="mb-10">
                  <TextInputField
                    label="Last name"
                    variant="filled"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        borderColor:
                          ((showError && errors.lastName)) && "#C75E5A",
                      },
                    }}
                    onChange={changeHandler}
                    name="lastName"
                    value={inputValues.lastName}
                    style={{ marginTop: 10, width: "100%" }}
                    InputLabelProps={{
                      style: {
                        color:
                          (showError && errors.lastName)
                            ? "#C75E5A"
                            : "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  />
                  {showError && errors.lastName && (
                    <p className="error-message">{errors.lastName}</p>
                  )}                          
                </div>
                <div className="mb-10">
                  <TextInputField
                    label="Phone"
                    variant="filled"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        borderColor:
                          (showError && errors.phoneNo) && "#C75E5A",
                      },
                    }}
                    onChange={changeHandler}
                    name="phoneNo"
                    value={inputValues.phoneNo}
                    style={{ marginTop: 10, width: "100%" }}
                    InputLabelProps={{
                      style: {
                        color:
                          (showError && errors.phoneNo)
                            ? "#C75E5A"
                            : "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  />
                  {showError && errors.phoneNo && (
                    <p className="error-message">{errors.phoneNo}</p>
                  )}
                </div>
                <div className="mb-10">
                  <TextInputField
                    label="Email"
                    variant="filled"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        borderColor:
                          (showError && errors.email) && "#C75E5A",
                      },
                    }}
                    onChange={changeHandler}
                    name="email"
                    value={inputValues.email}
                    style={{ marginTop: 10, width: "100%" }}
                    InputLabelProps={{
                      style: {
                        color:
                          (showError && errors.email)
                            ? "#C75E5A"
                            : "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  />
                  {showError && errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}                          
                </div>                
                <div className="mb-10">
                  <TextInputField
                    label="Company (optional)"
                    variant="filled"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        borderColor:
                          (showError && errors.company) && "#C75E5A",
                      },
                    }}
                    onChange={changeHandler}
                    name="company"
                    value={inputValues.company}
                    style={{ marginTop: 10, width: "100%" }}
                    InputLabelProps={{
                      style: {
                        color:
                          (showError && errors.company)
                            ? "#C75E5A"
                            : "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  />
                  {showError && errors.company && (
                    <p className="error-message">{errors.company}</p>
                  )}                          
                </div>                
                
                <div className="interest-level-container">
                  <TextInputField
                    select
                    label="Interest Level"
                    variant="filled"
                    onChange={changeHandler}
                    name="interestLevel"
                    className={classes.styleSelect}
                    value={inputValues.interestLevel}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        color:
                          showError && errors.interestLevel
                            ? "#C75E5A"
                            : "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                    InputProps={{
                      style: {
                        disableUnderline: true,
                        border: showError && errors.interestLevel && "1px solid #C75E5A",
                      },
                    }}
                  >
                    {INTEREST_LEVEL_ITEMS.map(({ value, text }) => (
                      <MenuItem
                        key={value}
                        className={classes.styleSelectMenuItem}
                        value={value}
                      >
                        {text}
                      </MenuItem>
                    ))}
                  </TextInputField>
                  {showError && errors.interestLevel && (
                    <p className="error-message">{errors.interestLevel}</p>
                  )}
                </div>

                <div className="continue-btn-container text-center">
                  <Button
                    onClick={() => {
                      if (!isValid) {
                        setShowError(true);
                      } else {
                        submitHandler();
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default EarlyAccessGateRegister;
